import { useCallback } from 'react'
import BigNumber from 'bignumber.js'
import { DEFAULT_TOKEN_DECIMAL, DEFAULT_GAS_LIMIT } from 'config'
import { BIG_TEN } from 'utils/bigNumber'
import { useSousChef } from 'hooks/useContract'
import getGasPrice from 'utils/getGasPrice'
import { getSouschefContract } from 'utils/contractHelpers'
import { useSattWallet } from 'state/satt/hooks'

const options = {
  gasLimit: DEFAULT_GAS_LIMIT,
}

const sousStake = async (sousChefContract, amount, decimals = 18) => {
  const gasPrice = getGasPrice()
  return sousChefContract.deposit(new BigNumber(amount).times(BIG_TEN.pow(decimals)).toString(), {
    ...options,
    gasPrice,
  })
}

const sousStakeBnb = async (sousChefContract, amount) => {
  const gasPrice = getGasPrice()
  return sousChefContract.deposit(new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString(), {
    ...options,
    gasPrice,
  })
}

const useStakePool = (sousId: number, isUsingBnb = false) => {
  let sousChefContract = useSousChef(sousId)
  const { sattAddress, getSattSigner } = useSattWallet()

  const handleStake = useCallback(
    async (amount: string, decimals: number, password?: string) => {
      if (sattAddress && password) {
        sousChefContract = getSouschefContract(sousId, getSattSigner(password))
      }

      if (isUsingBnb) {
        return sousStakeBnb(sousChefContract, amount)
      }

      return sousStake(sousChefContract, amount, decimals)
    },
    [isUsingBnb, sousChefContract],
  )

  return { onStake: handleStake }
}

export default useStakePool
