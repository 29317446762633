import { useCallback } from 'react'
import { BIG_ZERO } from 'utils/bigNumber'
import getGasPrice from 'utils/getGasPrice'
import { useSousChef } from 'hooks/useContract'
import { DEFAULT_GAS_LIMIT } from 'config'
import { useSattWallet } from 'state/satt/hooks'
import { getSouschefContract } from 'utils/contractHelpers'

const options = {
  gasLimit: DEFAULT_GAS_LIMIT,
}

const harvestPool = async (sousChefContract) => {
  const gasPrice = getGasPrice()
  return sousChefContract.deposit('0', { ...options, gasPrice })
}

const harvestPoolBnb = async (sousChefContract) => {
  const gasPrice = getGasPrice()
  return sousChefContract.deposit({ ...options, value: BIG_ZERO, gasPrice })
}

const useHarvestPool = (sousId, isUsingBnb = false) => {
  let sousChefContract = useSousChef(sousId)
  const { sattAddress, getSattSigner } = useSattWallet()

  const handleHarvest = useCallback(
    async (password?: string) => {
      if (sattAddress && password) {
        // eslint-disable-next-line
        sousChefContract = getSouschefContract(sousId, getSattSigner(password))
      }

      if (isUsingBnb) {
        return harvestPoolBnb(sousChefContract)
      }

      return harvestPool(sousChefContract)
    },
    [isUsingBnb, sousChefContract],
  )

  return { onReward: handleHarvest }
}

export default useHarvestPool
