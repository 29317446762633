import { useCallback, useMemo } from 'react'
import { useWeb3React } from '@web3-react/core'
import { Contract } from '@ethersproject/contracts'
import { MaxUint256 } from '@ethersproject/constants'
import { useAppDispatch } from 'state'
import { updateUserAllowance } from 'state/actions'
import { useTranslation } from 'contexts/Localization'
import { useCake, useSousChef, useVaultPoolContract } from 'hooks/useContract'
import useToast from 'hooks/useToast'
import { useCallWithGasPrice } from 'hooks/useCallWithGasPrice'
import useCatchTxError from 'hooks/useCatchTxError'
import { ToastDescriptionWithTx } from 'components/Toast'
import { useSWRContract, UseSWRContractKey } from 'hooks/useSWRContract'
import { useSattWallet } from 'state/satt/hooks'
import { getContract } from 'utils'
import bep20Abi from 'config/abi/erc20.json'

export const useApprovePool = (lpContract: Contract, sousId, earningTokenSymbol) => {
  const { toastSuccess } = useToast()
  const { fetchWithCatchTxError, loading: pendingTx } = useCatchTxError()
  const { callWithGasPrice } = useCallWithGasPrice()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const { sattAddress, getSattSigner } = useSattWallet()
  const address = account || sattAddress || undefined
  const sousChefContract = useSousChef(sousId)

  const handleApprove = useCallback(
    async (password?: string) => {
      if (sattAddress && password) {
        // eslint-disable-next-line
        lpContract = getContract(lpContract?.address, bep20Abi, getSattSigner(password))
      }

      const receipt = await fetchWithCatchTxError(() => {
        return callWithGasPrice(lpContract, 'approve', [sousChefContract.address, MaxUint256])
      })
      if (receipt?.status) {
        toastSuccess(
          t('Contract Enabled'),
          <ToastDescriptionWithTx txHash={receipt.transactionHash}>
            {t('You can now stake in the %symbol% pool!', { symbol: earningTokenSymbol })}
          </ToastDescriptionWithTx>,
        )
        // @ts-ignore
        dispatch(updateUserAllowance(sousId, address))
      }
    },
    [
      address,
      dispatch,
      lpContract,
      sousChefContract,
      sousId,
      earningTokenSymbol,
      t,
      toastSuccess,
      callWithGasPrice,
      fetchWithCatchTxError,
    ],
  )

  return { handleApprove, pendingTx }
}

// Approve CAKE auto pool
export const useVaultApprove = (setLastUpdated: () => void) => {
  const { t } = useTranslation()
  const { toastSuccess } = useToast()
  const { fetchWithCatchTxError, loading: pendingTx } = useCatchTxError()
  const vaultPoolContract = useVaultPoolContract()
  const { callWithGasPrice } = useCallWithGasPrice()
  const { signer: cakeContract } = useCake()

  const handleApprove = async () => {
    const receipt = await fetchWithCatchTxError(() => {
      return callWithGasPrice(cakeContract, 'approve', [vaultPoolContract.address, MaxUint256])
    })
    if (receipt?.status) {
      toastSuccess(
        t('Contract Enabled'),
        <ToastDescriptionWithTx txHash={receipt.transactionHash}>
          {t('You can now stake in the %symbol% vault!', { symbol: 'CAKE' })}
        </ToastDescriptionWithTx>,
      )
      setLastUpdated()
    }
  }

  return { handleApprove, pendingTx }
}

export const useCheckVaultApprovalStatus = () => {
  const { account } = useWeb3React()
  const { reader: cakeContract } = useCake()
  const vaultPoolContract = useVaultPoolContract()

  const key = useMemo<UseSWRContractKey>(
    () =>
      account
        ? {
            contract: cakeContract,
            methodName: 'allowance',
            params: [account, vaultPoolContract.address],
          }
        : null,
    [account, cakeContract, vaultPoolContract.address],
  )

  const { data, mutate } = useSWRContract(key)

  return { isVaultApproved: data ? data.gt(0) : false, setLastUpdated: mutate }
}
