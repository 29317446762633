import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  background: ",
        ";\n  border-radius: ",
        ";\n  ",
        "\n  \n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { space } from "styled-system";
var CardHeader = styled.div.withConfig({
    componentId: "sc-5a39cb26-0"
})(_templateObject(), function(param) {
    var theme = param.theme, _variant = param.variant, variant = _variant === void 0 ? "default" : _variant;
    return theme.card.cardHeaderBackground[variant];
}, function(param) {
    var theme = param.theme;
    return "".concat(theme.radii.card, " ").concat(theme.radii.card, " 0 0");
}, space);
CardHeader.defaultProps = {
    p: "24px"
};
export default CardHeader;
